import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { insertApproachApply, selectMaterialsByName, selectSupplierByName, selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import moment from 'moment';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      materials: [{
        materialsName: undefined,
        materialsSpecs: '',
        materialsNum: '',
        materialsNameData: []
      }],
      packingWay: '',
      transporteWay: '',
      unloadingRequire: '',
      contractName: '',
      approachTime: undefined,
      professional: undefined,
      fileUrl: [],
      itemId: null
    });
    const {
      checkName,
      checkPhone
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      packingWay: [{
        required: true,
        message: '包装方式不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      transporteWay: [{
        required: true,
        message: '进场运输方式不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      unloadingRequire: [{
        required: true,
        message: '卸货需求不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractName: [{
        required: true,
        message: '归属合同不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      approachTime: [{
        required: true,
        message: '进场时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      professional: [{
        required: true,
        message: '所属专业不能为空',
        trigger: 'change'
      }],
      supplierName: [{
        required: true,
        message: '供应商名称不能为空',
        trigger: 'blur'
      }],
      supplierContact: [{
        required: true,
        validator: checkName,
        // message: '供应商联系人不能为空',
        trigger: 'blur'
      }],
      supplierMobile: [{
        required: true,
        validator: checkPhone,
        // message: '联系方式不能为空',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //添加材料

    const addMaterialInformation = () => {
      formState.materials.push({
        materialsName: undefined,
        materialsSpecs: '',
        materialsNum: '',
        materialsNameData: []
      });
    }; //删除材料


    const deleteMaterialInformation = index => {
      formState.materials.splice(index, 1);
    }; //获取附件的url


    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.fileUrl = val;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.approachTime = obj.approachTime.startOf('day').format('YYYY-MM-DD');
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        loading.value = true;
        let res = await insertApproachApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    }; //材料名称查询


    const supplierNameData = ref([]);

    const handleSearch = (val, num, index) => {
      // console.log(val,index);
      if (num == 1) {
        fetchForMat(val, index);
      } else {
        fetchForSup(val);
      }
    };

    const handleChange = (val, num, index) => {
      if (num == 1) {
        fetchForMat(val, index);
      } else {
        fetchForSup(val);
      }
    };

    const fetchForMat = async (val, index) => {
      if (!val) {
        formState.materials[index].materialsNameData = [];
        return;
      }

      let res = await selectMaterialsByName(val); // console.log(res)

      try {
        formState.materials[index].materialsNameData = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            formState.materials[index].materialsNameData.push({
              value: ixt.materialsName,
              text: ixt.materialsName
            });
          }); //如果当前返回的数据中没有当前的值，则把当前值放进去

          let bool = res.data.some(ixt => ixt.materialsName === val);

          if (!bool && val !== " ") {
            formState.materials[index].materialsNameData.unshift({
              value: val,
              text: val
            });
          }
        } else {
          formState.materials[index].materialsNameData.push({
            value: val,
            text: val
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchForSup = async val => {
      if (!val) {
        supplierNameData.value = [];
        return;
      }

      let res = await selectSupplierByName(val); // console.log(res)

      try {
        supplierNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            supplierNameData.value.push({
              value: ixt.supplierName,
              text: ixt.supplierName
            });
          }); //如果当前返回的数据中没有当前的值，则把当前值放进去

          let bool = res.data.some(ixt => ixt.supplierName === val);

          if (!bool && val !== " ") {
            supplierNameData.value.unshift({
              value: val,
              text: val
            });
          }
        } else {
          supplierNameData.value.push({
            value: val,
            text: val
          });
        }
      } catch (error) {
        console.log(error);
      }
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 1,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //所属专业


    const professionalOptions = ref([]);

    const getProfessionalByItem = async () => {
      let res = await selectProfessionalByItem({
        itemId: formState.itemId
      });

      if (res.code === 200) {
        professionalOptions.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            professionalOptions.value.push({
              value: item.professionalName,
              label: item.professionalName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      addMaterialInformation,
      deleteMaterialInformation,
      handleOk,
      loading,
      getAdjuncts,
      disabledDate,
      handleSearch,
      handleChange,
      supplierNameData,
      projectOptions,
      professionalOptions,
      getProfessionalByItem
    };
  }

});